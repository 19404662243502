import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "services/AuthService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  showSuccessMessage: false,
  redirect: "",
  token: localStorage.getItem("AUTH_TOKEN") || null,
  username: "",
  messageType: null,
};

export const signIn = createAsyncThunk(
  "auth/login",
  async ({ mobile, password }, { rejectWithValue }) => {
    try {
      const response = await AuthService.login({ mobile, password });
      const { data } = response;
      if (data && data.status === "valid" && data.token) {
        localStorage.setItem("AUTH_TOKEN", data.token);
        return { token: data.token, username: data.username };
      } else {
        return rejectWithValue(data.message || "Login failed!");
      }
    } catch (error) {
      return rejectWithValue(error.message || "Login failed!");
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async ({ msg, type }) => {
    localStorage.removeItem("AUTH_TOKEN");
    return msg
      ? { message: msg, type: type }
      : { message: "Logged out Successfully!!", type: "success" };
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
      state.showSuccessMessage = false;
      state.messageType = "";
    },
    signOutSuccess: (state, action) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload.token;
        state.username = action.payload.username;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
        state.message = action.payload.message;
        state.messageType = action.payload.type;
        state.showSuccessMessage = true;
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setUsername,
} = authSlice.actions;

export default authSlice.reducer;
