import axiosProtectedInstance from "../auth/axiosProtectedInstance";

const UserService = {};

UserService.getAdminList = async function () {
  try {
    const response = await axiosProtectedInstance.get("/users/getAdmins");
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message || "Failed to get the admin records";
    throw new Error(errorMessage);
  }
};

UserService.deleteAdmin = async function (id) {
  try {
    const response = await axiosProtectedInstance.put(
      `/users/deleteAdminUser/${id}`
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to delete the admin record";
    throw new Error(errorMessage);
  }
};

UserService.addAdmin = async function (user) {
  try {
    const response = await axiosProtectedInstance.post(`/users/addAdmin`, {
      user,
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message || "Failed to add the admin record";
    throw new Error(errorMessage);
  }
};

UserService.getCustomers = async function (id) {
  try {
    let response = "";
    if (id !== null) {
      response = await axiosProtectedInstance.post("/users/getCustomers", {
        id,
      });
    } else {
      response = await axiosProtectedInstance.get("/users/getCustomers");
    }
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to get the Customer records";
    throw new Error(errorMessage);
  }
};

UserService.addCustomer = async function (user) {
  try {
    const response = await axiosProtectedInstance.post(`/users/addCustomer`, {
      user,
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to add the customer record";
    throw new Error(errorMessage);
  }
};

UserService.updateCustomer = async function (user) {
  try {
    const response = await axiosProtectedInstance.post(
      `/users/updateCustomer`,
      {
        user,
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to update the customer record";
    throw new Error(errorMessage);
  }
};

UserService.updatePassword = async function (values) {
  try {
    const response = await axiosProtectedInstance.post(
      `/users/updateAdminPassword`,
      {
        values,
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to update the customer record";
    throw new Error(errorMessage);
  }
};

export default UserService;
