import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";

const axiosPublicInstance = axios.create({
  baseURL: API_BASE_URL, // Set your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosPublicInstance;
