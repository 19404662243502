import axiosProtectedInstance from "../auth/axiosProtectedInstance";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
//import { saveAs } from "file-saver";
import InvoiceComponent from "../MyViews/TicketsView/InvoiceComponent";
import { createRoot } from "react-dom/client";

const ticketService = {};

ticketService.addTicket = async function (ticket) {
  try {
    const response = await axiosProtectedInstance.post(`/tickets/addTicket`, {
      ticket,
    });

    if (
      response.status === 201 &&
      response.data.ticketData &&
      ticket.downloadTicket === true
    ) {
      const ticketData = {
        ...response.data.ticketData, // Include all existing ticket data from the response
        addServiceCharge: ticket.addServiceCharge, // Include addServiceCharge from the ticket prop
        includeGST: ticket.includeGST, // Include includeGST from the ticket prop
      };
      await ticketService.generatePDF(ticketData);
    }

    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to Add the Ticket!. Kindly try again or contact Admin";
    return { errorMessage };
  }
};

ticketService.updateTicket = async function (ticket) {
  try {
    const response = await axiosProtectedInstance.post(
      `/tickets/updateTicketDetails`,
      {
        ticket,
      }
    );
    if (
      response.status === 200 &&
      response.data.ticketData &&
      ticket.downloadTicket === true
    ) {
      const ticketData = {
        ...response.data.ticketData, // Include all existing ticket data from the response
        addServiceCharge: ticket.addServiceCharge, // Include addServiceCharge from the ticket prop
        includeGST: ticket.includeGST, // Include includeGST from the ticket prop
      };
      await ticketService.generatePDF(ticketData);
    }

    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to Update the Ticket!. Kindly try again or contact Admin";
    return { errorMessage };
  }
};

ticketService.getTickets = async function () {
  try {
    const response = await axiosProtectedInstance.get("/tickets/gettickets");
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message || "Failed to create the ticket!";
    throw new Error(errorMessage);
  }
};

ticketService.getSingleTicket = async function (value) {
  try {
    const response = await axiosProtectedInstance.get(
      `/tickets/getSingleTicket`,
      {
        params: { value },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to get the ticket details!";
    return { errorMessage };
  }
};

ticketService.addCredit = async function (values) {
  try {
    const response = await axiosProtectedInstance.post(`/tickets/addCredit`, {
      values,
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to Add the Credit Entry!. Kindly try again or contact Admin";
    return { errorMessage };
  }
};

ticketService.updateCredit = async function (values) {
  try {
    const response = await axiosProtectedInstance.post(
      `/tickets/updateCredit`,
      {
        values,
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to Update the Credit Note!. Kindly try again or contact Admin";
    return { errorMessage };
  }
};

ticketService.generatePDF = async (ticketData) => {
  // Create a temporary div to render the invoice HTML
  const tempDiv = document.createElement("div");
  tempDiv.style.position = "fixed";
  tempDiv.style.left = "-9999px"; // Hide the element offscreen

  document.body.appendChild(tempDiv);

  // Create a root and render the InvoiceComponent into the temporary div
  const root = createRoot(tempDiv);
  const renderPromise = new Promise((resolve) => {
    root.render(
      <InvoiceComponent
        ticketData={ticketData}
        onRenderComplete={() => {
          // This callback will be called when all images are loaded and component is fully rendered
          resolve();
        }}
      />
    );
  });
  // Wait for the component to render and all images to load
  await renderPromise;

  // Use html2canvas to capture the rendered HTML
  const canvas = await html2canvas(tempDiv, {
    logging: false, // Disable logging
    useCORS: true, // Enable CORS for images
    scale: 2, // Increase resolution
  });

  // Create PDF
  const pdf = new jsPDF("p", "mm", "a4");
  const imgData = canvas.toDataURL("image/png");
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio
  pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

  // Use FileSaver to download the PDF
  pdf.save(`TicketInv_${ticketData.ticketId}.pdf`);

  // Clean up
  root.unmount();
  document.body.removeChild(tempDiv);
};

ticketService.transferTicket = async function (requestData) {
  try {
    const response = await axiosProtectedInstance.post(
      `/tickets/transferTicket`,
      {
        requestData,
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to Transfer the Ticket's, kindly try again or contact admin!";
    return { errorMessage };
  }
};

ticketService.transferCredit = async function (requestData) {
  try {
    const response = await axiosProtectedInstance.post(
      `/tickets/transferCreditEntry`,
      {
        requestData,
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to Transfer the Credit Entry, kindly try again or contact admin!";
    return { errorMessage };
  }
};

export default ticketService;
