import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Table, Divider, Card, Image } from "antd";
import "./InvoiceComponentStyle.css";
const numWords = require("num-words");

const { Title, Text } = Typography;

const InvoiceComponent = ({ ticketData, onRenderComplete }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = 2;

  useEffect(() => {
    if (imagesLoaded === totalImages) {
      onRenderComplete();
    }
  }, [imagesLoaded, onRenderComplete]);

  const handleImageLoad = () => {
    setImagesLoaded((prev) => prev + 1);
  };

  let columns;

  if (ticketData.mode === "Visa" || ticketData.mode === "Miscellaneous") {
    columns = [
      {
        title: "Pax Name",
        dataIndex: "paxName",
        key: "paxName",
      },
      {
        title: `${ticketData.mode}Details`,
        dataIndex: "modeDetails",
        key: "modeDetails",
      },
    ];
  } else if (ticketData.mode === "MultiCity") {
    columns = [
      {
        title: "PNR No",
        dataIndex: "PNR",
        key: "PNR",
        render: (div, record, index) => {
          if (index === 0) {
            return {
              children: div,
              props: {
                rowSpan: ticketData.sectorsinfo.Total_Sectors,
              },
            };
          }
          return {
            props: {
              rowSpan: 0,
            },
          };
        },
      },
      {
        title: "Pax Name",
        dataIndex: "paxName",
        key: "paxName",
        render: (div, record, index) => {
          if (index === 0) {
            return {
              children: div,
              props: {
                rowSpan: ticketData.sectorsinfo.Total_Sectors,
              },
            };
          }
          return {
            props: {
              rowSpan: 0,
            },
          };
        },
      },
      { title: "Sector", dataIndex: "sector", key: "sector" },
      {
        title: "Travel Date",
        dataIndex: "dot",
        key: "dot",
      },
      { title: "Details", dataIndex: "modeDetails", key: "modeDetails" },
    ];
  } else {
    columns = [
      {
        title: "PNR No",
        dataIndex: "PNR",
        key: "PNR",
        render: (div, record, index) => {
          if (index === 0) {
            return {
              children: div,
              props: {
                rowSpan: 2,
              },
            };
          }
          return {
            props: {
              rowSpan: 0,
            },
          };
        },
      },
      {
        title: "Pax Name",
        dataIndex: "paxName",
        key: "paxName",
        render: (div, record, index) => {
          if (index === 0) {
            return {
              children: div,
              props: {
                rowSpan: 2,
              },
            };
          }
          return {
            props: {
              rowSpan: 0,
            },
          };
        },
      },
      { title: "Sector", dataIndex: "sector", key: "sector" },
      {
        title: `${
          ticketData.mode === "Hotel" ? "Check In & Out Dates" : "Travel Date"
        }`,
        dataIndex: "dot",
        key: "dot",
      },
      { title: "Details", dataIndex: "modeDetails", key: "modeDetails" },
    ];
  }

  let dataSource;
  if (ticketData.mode === "Airline RW") {
    dataSource = [
      {
        key: "1",
        paxName: ticketData.paxName,
        dot: ticketData.dot,
        sector: `${ticketData.from} - ${ticketData.to}`,
        modeDetails: ticketData.modeDetails,
        PNR: ticketData.pnr,
      },
      {
        key: "2",
        paxName: ticketData.paxName, // This won't be rendered due to rowSpan
        dot: ticketData.rdate,
        sector: `${ticketData.to} - ${ticketData.from}`,
        modeDetails: ticketData.rmodedetails,
        PNR: ticketData.pnr,
      },
    ];
  } else if (
    ticketData.mode === "Airline OW" ||
    ticketData.mode === "Train" ||
    ticketData.mode === "Bus"
  ) {
    dataSource = [
      {
        key: "1",
        paxName: ticketData.paxName,
        sector: `${ticketData.from} - ${ticketData.to}`,
        dot: ticketData.dot,
        modeDetails: ticketData.modeDetails,
        PNR: ticketData.pnr,
      },
    ];
  } else if (ticketData.mode === "Hotel") {
    dataSource = [
      {
        key: "1",
        paxName: ticketData.paxName,
        sector: `${ticketData.sector}`,
        dot: `${ticketData.checkin} Till ${ticketData.checkout}`,
        modeDetails: ticketData.modeDetails,
        PNR: ticketData.pnr,
      },
    ];
  } else if (
    ticketData.mode === "Visa" ||
    ticketData.mode === "Miscellaneous"
  ) {
    dataSource = [
      {
        key: "1",
        paxName: ticketData.paxName,
        modeDetails: ticketData.modeDetails
          ? ticketData.modeDetails
          : ticketData.narration,
      },
    ];
  } else if (ticketData.mode === "MultiCity") {
    dataSource = [
      {
        key: "1",
        paxName: ticketData.paxName,
        dot: ticketData.dot,
        sector: `${ticketData.from} - ${ticketData.to}`,
        modeDetails: ticketData.modeDetails,
        PNR: ticketData.pnr,
      },
    ];
    for (let i = 1; i < ticketData.sectorsinfo.Total_Sectors; i++) {
      dataSource.push({
        key: (i + 1).toString(),
        paxName: ticketData.paxName,
        dot: ticketData.sectorsinfo[`Dot_${i}`],
        sector: `${ticketData.sectorsinfo[`FromCity_${i}`]} - ${
          ticketData.sectorsinfo[`ToCity_${i}`]
        }`,
        modeDetails: ticketData.sectorsinfo[`ModeDetails_${i}`],
        PNR: ticketData.pnr,
      });
    }
  }
  const capitalizeWords = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

  const totalFareInWords =
    capitalizeWords(numWords(ticketData.totalFare)) + " Only";

  const summaryData = [
    { key: "1", label: "Subtotal", value: "₹ " + ticketData.basicFare },
    { key: "2", label: "Tax", value: "₹ " + ticketData.tax1 },
    {
      key: "3",
      label: "Service Charge",
      value: "₹ " + ticketData.processingFees,
    },
  ];

  if (ticketData.includeGST) {
    summaryData.push({
      key: "4",
      label: "CGST @9.00%",
      value: "₹ " + ticketData.tax / 2,
    });
    summaryData.push({
      key: "5",
      label: "SGST @9.00%",
      value: "₹ " + ticketData.tax / 2,
    });
  }

  const summaryColumns = [
    { dataIndex: "label", key: "label" },
    { dataIndex: "value", key: "value", align: "right" },
  ];

  // Styles for better text handling
  const headerStyles = {
    fontSize: "1rem",
    marginBottom: "0.5rem",
    color: "#333",
  };

  const textContainerStyles = {
    minHeight: "100px",
    height: "auto",
    overflow: "hidden",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    fontSize: "0.8rem",
  };

  const addressStyles = {
    fontSize: "1rem",
    lineHeight: "1.5",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  };

  return (
    <Card bordered={false} className="invoice-container">
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col-12">
            <img
              src="/img/printlogo.png"
              alt="Logo"
              height="140"
              width="100"
              style={{ marginBottom: "10px", marginTop: "0px" }}
              onLoad={handleImageLoad}
              class="img-fluid"
            />
          </div>
        </div>
        {/* Updated header section with better text handling */}
        <Row gutter={[16, 16]} className="header-section">
          <Col xs={24} sm={8} className="address-column">
            <Title level={5} style={headerStyles}>
              From:
            </Title>
            <div style={textContainerStyles}>
              <div style={addressStyles}>
                <span style={{ fontWeight: "bolder", color: "#9e1068" }}>
                  STAR TOURS & TRAVELS
                </span>
              </div>
              <div style={addressStyles}>
                <span style={{ fontWeight: "bolder", color: "#9e1068" }}>
                  Prop : Murtuza Saify
                </span>
              </div>
              <div style={addressStyles}>
                2, Mahatma Gandhi Square, Piplani BHEL
                <br />
                Bhopal City, Madhya Pradesh, 462022
                <br />
                <span style={{ fontWeight: "bolder" }}>Email: </span>
                startravels52@gmail.com
                <br />
                <span style={{ fontWeight: "bolder" }}>Mobile: </span>+91 98067
                61974
                <br />
                <span style={{ fontWeight: "bolder" }}>PAN No: </span>KZQPS1105B
              </div>
            </div>
          </Col>

          <Col xs={24} sm={8} className="address-column">
            <Title level={5} style={headerStyles}>
              To:
            </Title>
            <div style={textContainerStyles}>
              <div style={addressStyles}>
                <span style={{ fontWeight: "bolder", color: "#006d75" }}>
                  {ticketData.customer.name}
                </span>
              </div>
              {ticketData.customer.gst && (
                <div style={addressStyles}>
                  <span style={{ fontWeight: "bolder" }}>GST No : </span>
                  {ticketData.customer.gst}
                </div>
              )}
              {ticketData.customer.address && (
                <div style={addressStyles}>
                  <span style={{ fontWeight: "bolder" }}>Address : </span>
                  {ticketData.customer.address}
                </div>
              )}
              {ticketData.customer.email && (
                <div style={addressStyles}>
                  <span style={{ fontWeight: "bolder" }}>Email: </span>
                  {ticketData.customer.email}
                </div>
              )}
              {ticketData.customer.mobile && (
                <div style={addressStyles}>
                  <span style={{ fontWeight: "bolder" }}>Mobile: </span>
                  {ticketData.customer.mobile}
                </div>
              )}
            </div>
          </Col>

          <Col xs={24} sm={8} className="details-column">
            <Title level={5} style={headerStyles}>
              Ticket Details:
            </Title>
            <div style={textContainerStyles}>
              <div style={addressStyles}>
                <span style={{ fontWeight: "bolder" }}>
                  Invoice No. : {ticketData.ticketId}
                </span>
              </div>
              <div style={addressStyles}>
                <span style={{ fontWeight: "bolder" }}>Date & Time : </span>
                {ticketData.date}
              </div>
              <div style={addressStyles}>
                <span style={{ fontWeight: "bolder", color: "#9e1068" }}>
                  Mode : {ticketData.mode}
                </span>
              </div>
              {ticketData.mode !== "Visa" &&
                ticketData.mode !== "Miscellaneous" && (
                  <div style={addressStyles}>
                    <span style={{ fontWeight: "bolder" }}>Narration 1: </span>
                    {ticketData.narration}
                  </div>
                )}
              {ticketData.mode === "Airline RW" && (
                <div style={addressStyles}>
                  <span style={{ fontWeight: "bolder" }}>Narration 2: </span>
                  {ticketData.rnarration}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        size="small"
        className="invoice-table"
      />

      <Divider />

      <Row gutter={16}>
        <Col span={12}>
          <Title level={4} style={{ color: "#4096ff" }}>
            Bank Details :-
          </Title>
          <Text>
            ACCOUNT NUMBER : 116763300000527
            <br />
            ACCOUNT NAME : STAR TOURS AND TRAVELS
            <br />
            BANK NAME : YES BANK
            <br />
            IFSC CODE : YESB0001167
          </Text>
        </Col>
        <Col span={12}>
          <Table
            columns={summaryColumns}
            dataSource={summaryData}
            showHeader={false}
            pagination={false}
            bordered={true}
            size="small"
          />
        </Col>
      </Row>

      <Divider />

      <Row gutter={16} style={{ marginTop: "12px" }}>
        <Col span={5}>
          <Text strong style={addressStyles}>
            Total Fare in Words:
          </Text>
        </Col>
        <Col span={8}>
          <Text strong style={addressStyles}>
            <span style={{ color: "#9e1068" }}>{totalFareInWords}</span>
          </Text>
        </Col>
        <Col span={5} style={{ textAlign: "right", fontWeight: "bolder" }}>
          <Text strong style={addressStyles}>
            Total Invoice Amount:
          </Text>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Text strong style={{ color: "#780650", fontSize: "1rem" }}>
            ₹ {ticketData.totalFare}
          </Text>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Title level={5} style={{ color: "#cf1322", marginTop: "16px" }}>
            Terms & Condition :-
          </Title>
          <Text style={{ fontSize: "14px" }}>
            1. Payment for this bill shall be through Cheque/RTGS in favour of{" "}
            <strong>Star Tours & Travels.</strong>
            <br />
            2. Any dispute with regard to this invoice to be raised within 2
            Days from the date of receipt of this bill.
            <br />
            3. Kindly check all details carefully to avoid un-necessary
            complications.
            {ticketData.addServiceCharge ? (
              <>
                <br />
                4. Service Charge - ₹500 for Flight and ₹150 for Train Bookings.
              </>
            ) : (
              ""
            )}
          </Text>
        </Col>
        <Col span={12}>
          <br />
          <br />
          <div style={{ textAlign: "right" }}>
            <Text strong style={{ color: "#9e1068" }}>
              For STAR TOURS AND TRAVELS
            </Text>
            <br />
            <Image
              src="/img/Signature.jpeg"
              alt="Signature"
              style={{ width: "150px", height: "60px", marginTop: "5px" }}
              onLoad={handleImageLoad}
            />
            <br />
            <Text strong>Authorized Signature</Text>
          </div>
        </Col>
      </Row>

      <Divider />
      {/* <div style={{ textAlign: "center" }}>
        <h6>
          This is a computer generated document and does not require any
          signature
        </h6>
      </div> */}
    </Card>
  );
};

export default InvoiceComponent;
