import axiosProtectedInstance from "../auth/axiosProtectedInstance";

const dashboardService = {};

dashboardService.getDashboardDetails = async function () {
  try {
    const response = await axiosProtectedInstance.get(
      "/users/getDashboardDetails"
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to get the dashboard details!";
    throw new Error(errorMessage);
  }
};

export default dashboardService;
