import axiosProtectedInstance from "../auth/axiosProtectedInstance";

const statementService = {};

statementService.getStatement = async function () {
  try {
    const response = await axiosProtectedInstance.get(
      "/statement/getCompleteStatement"
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message ||
      "Failed to get the statement list!";
    throw new Error(errorMessage);
  }
};

export default statementService;
