import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TicketService from "services/TicketService";

const initialState = {
  AllTickets: [],
  singleTicket: [],
  singleCredit: [],
  loading: false,
  SliceError: null,
  displayMessage: "",
  showMessage: false,
};

// Async thunks
export const getTickets = createAsyncThunk(
  "tickets/getTickets",
  async (_, { rejectWithValue }) => {
    try {
      const response = await TicketService.getTickets();
      return response.ticketDetail;
    } catch (error) {
      return rejectWithValue(error.displayMessage || "Failed to get tickets !");
    }
  }
);

export const getSingleTicket = createAsyncThunk(
  "tickets/getSingleTicket",
  async (value, { rejectWithValue }) => {
    try {
      const response = await TicketService.getSingleTicket(value);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return response.ticketDetail;
    } catch (error) {
      return rejectWithValue(error.displayMessage || "Failed to get tickets !");
    }
  }
);

export const getSingleCredit = createAsyncThunk(
  "tickets/getSingleCredit",
  async (value, { rejectWithValue }) => {
    try {
      const response = await TicketService.getSingleCredit(value);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return response.creditDetail;
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to get Credit Entry !"
      );
    }
  }
);

export const addTicket = createAsyncThunk(
  "tickets/addTicket",
  async (ticket, { rejectWithValue }) => {
    try {
      const response = await TicketService.addTicket(ticket);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to create the ticket!"
      );
    }
  }
);

export const updateTicket = createAsyncThunk(
  "tickets/updateTicket",
  async (ticket, { rejectWithValue }) => {
    try {
      const response = await TicketService.updateTicket(ticket);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to Update the ticket!"
      );
    }
  }
);

export const transferTicket = createAsyncThunk(
  "tickets/transferTicket",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await TicketService.transferTicket(requestData);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to transfer the ticket!"
      );
    }
  }
);

export const addCredit = createAsyncThunk(
  "tickets/addCredit",
  async (values, { rejectWithValue }) => {
    try {
      const response = await TicketService.addCredit(values);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to create the Credit Entry!"
      );
    }
  }
);

export const updateCredit = createAsyncThunk(
  "tickets/updateCredit",
  async (values, { rejectWithValue }) => {
    try {
      const response = await TicketService.updateCredit(values);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to Update the Credit Note!"
      );
    }
  }
);

export const transferCredit = createAsyncThunk(
  "tickets/transferCredit",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await TicketService.transferCredit(requestData);
      if (response.errorMessage) {
        return rejectWithValue(response.errorMessage);
      }
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to transfer the Credit!"
      );
    }
  }
);

// Ticket slice
const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.displayMessage = "";
    },
    hideMessage: (state) => {
      state.displayMessage = "";
      state.showMessage = false;
      state.SliceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.AllTickets = action.payload;
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.displayMessage;
        state.showMessage = true;
      })
      .addCase(addTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.displayMessage = action.payload.message;
        state.showMessage = true;
      })
      .addCase(addTicket.rejected, (state, action) => {
        state.loading = false;
        state.SliceError = action.payload;
        state.showMessage = true;
      })
      .addCase(transferTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(transferTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.displayMessage = action.payload.message;
        state.showMessage = true;
      })
      .addCase(transferTicket.rejected, (state, action) => {
        state.loading = false;
        state.SliceError = action.payload;
        state.showMessage = true;
      })
      .addCase(getSingleTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.singleTicket = action.payload;
      })
      .addCase(getSingleTicket.rejected, (state, action) => {
        state.loading = false;
        state.SliceError = action.payload;
        state.showMessage = true;
      })
      .addCase(updateTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.displayMessage = action.payload.message;
        state.showMessage = true;
      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.loading = false;
        state.SliceError = action.payload;
        state.showMessage = true;
      })
      .addCase(addCredit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCredit.fulfilled, (state, action) => {
        state.loading = false;
        state.displayMessage = action.payload.message;
        state.showMessage = true;
      })
      .addCase(addCredit.rejected, (state, action) => {
        state.loading = false;
        state.SliceError = action.payload;
        state.showMessage = true;
      })
      .addCase(updateCredit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCredit.fulfilled, (state, action) => {
        state.loading = false;
        state.displayMessage = action.payload.message;
        state.showMessage = true;
      })
      .addCase(updateCredit.rejected, (state, action) => {
        state.loading = false;
        state.SliceError = action.payload;
        state.showMessage = true;
      })
      .addCase(transferCredit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(transferCredit.fulfilled, (state, action) => {
        state.loading = false;
        state.displayMessage = action.payload.message;
        state.showMessage = true;
      })
      .addCase(transferCredit.rejected, (state, action) => {
        state.loading = false;
        state.SliceError = action.payload;
        state.showMessage = true;
      });
  },
});

export const { clearMessage, hideMessage } = ticketSlice.actions;
export default ticketSlice.reducer;
