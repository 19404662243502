import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
} from "configs/AppConfig";

import { signOut } from "../store/slices/authSlice";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };

  if (!token || isTokenExpired(token)) {
    if (token) {
      const msg = "Session timed out, Kindly login again!";
      const type = "warning";
      dispatch(signOut({ msg, type }));
    }
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
