import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "services/dashboardService";

const initialState = {
  NumberDetails: [],
  Customer: [],
  Transactions: [],
};

// Async thunks
export const getDashboardDetails = createAsyncThunk(
  "dashboard/getDashboardDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getDashboardDetails();
      return response;
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to fetch the statement!"
      );
    }
  }
);

// Ticket slice
const ticketSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.displayMessage = "";
    },
    hideMessage: (state) => {
      state.displayMessage = "";
      state.showMessage = false;
      state.SliceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDashboardDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.NumberDetails = [
          action.payload.ticketsCreatedToday,
          action.payload.totalCustomers,
          action.payload.currentMonthDebitSum,
          action.payload.currentMonthCreditSum,
        ];
        state.Customer = action.payload.latestCustomers;
        state.Transactions = action.payload.latestTransactions;
      })
      .addCase(getDashboardDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.displayMessage;
        state.showMessage = true;
      });
  },
});

export const { clearMessage, hideMessage } = ticketSlice.actions;
export default ticketSlice.reducer;
