import axiosPublicInstance from "../auth/axiosPublicInstance";

const AuthService = {};

AuthService.login = async function (data) {
  try {
    const response = await axiosPublicInstance.post("/auth/login", data);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.data?.message || "Login failed due to Connectivity";
    throw new Error(errorMessage);
  }
};

export default AuthService;
