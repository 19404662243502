import axios from "axios";
import store from "../store/index";
import { signOut } from "../store/slices/authSlice";
import { jwtDecode } from "jwt-decode";
import { API_BASE_URL } from "configs/AppConfig";

const axiosProtectedInstance = axios.create({
  baseURL: API_BASE_URL, // Set your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the token to headers
axiosProtectedInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration
axiosProtectedInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token might be expired
      const state = store.getState();
      const token = state.auth.token;
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp < currentTime) {
            // Token expired, sign out user
            const msg = "Session timed out, Kindly login again!";
            store.dispatch(signOut(msg));
          }
        } catch (err) {
          console.error("Error decoding token:", err);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosProtectedInstance;
