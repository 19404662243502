import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import statementService from "services/StatementService";

const initialState = {
  AllStatement: [],
  singleEntry: [],
  loading: false,
  SliceError: null,
  displayMessage: "",
  showMessage: false,
};

// Async thunks
export const getStatement = createAsyncThunk(
  "tickets/getStatement",
  async (_, { rejectWithValue }) => {
    try {
      const response = await statementService.getStatement();
      return response.statementDetail;
    } catch (error) {
      return rejectWithValue(
        error.displayMessage || "Failed to fetch the statement!"
      );
    }
  }
);

// export const getSingleEntry = createAsyncThunk(
//   "tickets/getSingleEntry",
//   async (value, { rejectWithValue }) => {
//     try {
//       const response = await statementService.getSingleEntry(value);
//       if (response.errorMessage) {
//         return rejectWithValue(response.errorMessage);
//       }
//       return response.ticketDetail;
//     } catch (error) {
//       return rejectWithValue(error.displayMessage || "Failed to get tickets !");
//     }
//   }
// );

// Ticket slice
const ticketSlice = createSlice({
  name: "statement",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.displayMessage = "";
    },
    hideMessage: (state) => {
      state.displayMessage = "";
      state.showMessage = false;
      state.SliceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.AllStatement = action.payload;
      })
      .addCase(getStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.displayMessage;
        state.showMessage = true;
      });
    // .addCase(getSingleEntry.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(getSingleEntry.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.singleTicket = action.payload;
    // })
    // .addCase(getSingleEntry.rejected, (state, action) => {
    //   state.loading = false;
    //   state.SliceError = action.payload;
    //   state.showMessage = true;
    // });
  },
});

export const { clearMessage, hideMessage } = ticketSlice.actions;
export default ticketSlice.reducer;
