import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "services/UserService";

const initialState = {
  users: [],
  loading: false,
  error: null,
  message: "",
  showMessage: false,
  customers: [],
  singleCustomer: [],
};

// Async thunks
export const getAdminUsers = createAsyncThunk(
  "users/getAdminUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserService.getAdminList();
      return response.users;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to get admin records!");
    }
  }
);

export const addAdminUser = createAsyncThunk(
  "users/addAdminUser",
  async (user, { rejectWithValue }) => {
    try {
      const response = await UserService.addAdmin(user);
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to add the admin record!"
      );
    }
  }
);

export const deleteAdminUser = createAsyncThunk(
  "users/deleteAdminUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.deleteAdmin(id);
      return { id, ...response };
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to update the admin record!"
      );
    }
  }
);

export const getCustomers = createAsyncThunk(
  "users/getCustomers",
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.getCustomers(id);
      return { users: response.customers, id: id };
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to get Customer records!"
      );
    }
  }
);

export const addCustomer = createAsyncThunk(
  "users/addCustomer",
  async (user, { rejectWithValue }) => {
    try {
      const response = await UserService.addCustomer(user);
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to add the Customer record!"
      );
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "users/updateCustomer",
  async (user, { rejectWithValue }) => {
    try {
      const response = await UserService.updateCustomer(user);
      return { ...response };
    } catch (error) {
      return rejectWithValue(
        error.message || "Failed to update the Customer record!"
      );
    }
  }
);

// User slice
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.message = "";
    },
    hideMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getAdminUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload.id ? "" : action.payload.users;
        state.singleCustomer = action.payload.id ? action.payload.users : "";
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addAdminUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAdminUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.showMessage = true;
      })
      .addCase(addAdminUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.showMessage = true;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteAdminUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAdminUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.map((user) =>
          user.Id === action.payload.Id ? action.payload.updatedUser : user
        );
        state.message = action.payload.message;
        state.showMessage = true;
      })
      .addCase(deleteAdminUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.showMessage = true;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearMessage, hideMessage } = userSlice.actions;
export default userSlice.reducer;
