import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import users from "./slices/userSlice";
import tickets from "./slices/ticketSlice";
import statement from "./slices/statementSlice";
import dashboard from "./slices/dashboardSlice";
const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    users,
    tickets,
    statement,
    dashboard,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
